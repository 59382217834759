import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite';

import { Alert, Card, Table, OverlayTrigger, Popover, PopoverContent } from 'react-bootstrap';
import SelectedServicesPanel from '~/components/app/order_form/SelectedServicesPanel';

import { CHOOSE_FOR_ME_VENDOR_ID } from '~/helpers/constants';
import { durationToDisplay, fileDuration } from '~/helpers/files';
import { durationString, numberToCurrency } from '~/helpers/numbers';
import { userLogger } from '~/logic/UserLogger';
import DurationMinimumNote, {
  CHOOSE_FOR_ME_VENDOR,
} from '~/components/app/order_form/DurationMinimumNote';
import { selectedServicesType } from '~/components/app/order_form/propTypes';

import {
  hasChooseForMeAudioDescription,
  hasDubbing,
  hasServiceWith3playMinimunDuration,
  hasTranslation,
  hasVoiceArtistAudioDescription,
  vendorTranscription,
} from '../helpers/selectedServicesHelpers';
import { ProjectContext } from '../OrderForm';

// See https://stackoverflow.com/a/11832950 for details on Number.EPSILON usage
async function priceForAllServices(services, duration, languages, token) {
  const servicePayloads = [];
  services.map((service) => {
    const payload = serviceDetails(service, languages);
    servicePayloads.push(...payload);
  });
  const priceInfo = await getPrice(token, duration, servicePayloads);

  const pricerate = priceInfo.pricerate;
  let price;
  if (priceInfo.price === null) {
    price = null;
  } else {
    price = Math.round((priceInfo.price + Number.EPSILON) * 100) / 100;
  }

  return {
    price: price,
    pricerate: pricerate,
  };
}

const getPrice = async (token, duration, payload) => {
  const data = new FormData();
  data.append('authenticity_token', token);
  data.append('duration', duration);
  data.append('serviceDetails', JSON.stringify(payload));

  const priceData = await fetch('/service_pricing/compute', {
    method: 'POST',
    body: data,
  }).then((response) => response.json());

  return { price: priceData.price, pricerate: priceData.pricerate };
};

function serviceDetails(service, languages) {
  const payload = {
    serviceType: service.serviceType,
    languages: languages,
    orderOption: null,
    serviceLevel: null,
    turnaroundLevel: null,
  };

  switch (service.serviceType) {
    case 'Transcription': {
      if (vendorTranscription(service)) {
        payload.serviceType = 'VendorTranscription';
      }
      payload.languages = service.orderOptions.language.ids;
      payload.turnaroundLevel = parseInt(service.orderOptions.turnaroundLevel.id);
      break;
    }
    case 'AudioDescription': {
      payload.serviceLevel = service.orderOptions.serviceLevel.name;
      payload.turnaroundLevel = parseInt(service.orderOptions.turnaroundLevel.id);
      const isVoiceArtist = service.orderOptions.speakerType?.name === 'Voice Artist';
      if (isVoiceArtist) {
        payload.serviceType = 'AudioDescriptions::VoiceOvers::VoiceOverAudioDescription';
      }
      break;
    }
    case 'CaptionPlacement': {
      payload.serviceLevel = service.orderOptions.serviceLevel.name;
      break;
    }
    case 'DescriptiveTranscript': {
      payload.serviceType = 'DescriptiveTranscriptions::DescriptiveTranscript';
      break;
    }
    case 'Dubbing': {
      // Human Dubbing is very project dependent, and prices are not calculated in the order form.
      if (service.orderOptions.selectedDubbingOptions[0].dubbingType.toLowerCase() === 'human') {
        return [];
      }

      const payloads = service.orderOptions.selectedDubbingOptions.map((option) => {
        const optionPayload = { ...payload };
        optionPayload.serviceType = 'Dubbings::AI::Dubbing';
        optionPayload.orderOption = option.id;
        return optionPayload;
      });

      return payloads;
    }
    case 'Translation': {
      const payloads = service.orderOptions.selectedOptions.map((option) => {
        const optionPayload = { ...payload };
        if (option.vendor.id == CHOOSE_FOR_ME_VENDOR_ID) {
          optionPayload.serviceType = 'Transcriptions::Localizations::Localization';
        }
        // Because there are so many translation order options, we don't send them along
        optionPayload.orderOption = {
          targetLanguage: option.targetLanguage,
          translationProfile: parseInt(option.translationProfile),
          translationVendor: parseInt(option.serviceLevel.translationVendor.id),
        };
        optionPayload.serviceLevel = parseInt(option.serviceLevel.id);

        return optionPayload;
      });

      return payloads;
    }
    case 'BroadcastScript': {
      const payloads = service.orderOptions.scriptFormats.map((format) => {
        const optionPayload = { ...payload };
        optionPayload.serviceType = 'BroadcastScriptings::BroadcastScripting';
        optionPayload.orderOption = parseInt(format.id);
        return optionPayload;
      });

      return payloads;
    }
  }

  return [payload];
}

function missingDurations(mediaFiles) {
  return mediaFiles.some((mf) => mf.duration === undefined || mf.duration === null);
}

function noDuration(mediaFiles) {
  return mediaFiles.every((mf) => mf.duration === undefined || mf.duration === null);
}

function durationUnderMinimum(props, durationMinimums) {
  //checks to see if any file is under the minimum duration of any service ordered
  //durations are in milliseconds, minimums are in seconds
  let minDuration = undefined;
  let serviceMinimum = undefined;
  props.selectedServices.forEach((ss) => {
    switch (ss.serviceType) {
      case 'Transcription':
        serviceMinimum = parseInt(durationMinimums.transcription, 10) * 1000;
        break;
      case 'AudioDescription':
        serviceMinimum = parseInt(durationMinimums.audioDescription, 10) * 1000;
        break;
    }
    if (minDuration) {
      minDuration = Math.max(minDuration, serviceMinimum);
    } else {
      minDuration = serviceMinimum;
    }
  });
  return props.mediaFiles.some((mf) => mf.duration < minDuration);
}

function totalDuration(mediaFiles) {
  const filesWithDuration = mediaFiles.filter((mf) => mf.duration != null);
  return filesWithDuration.reduce((acc, file) => acc + fileDuration(file), 0);
}

function voiceArtistWorkTooShort(mediaFiles, durationMinimums) {
  const minimumDuration = parseInt(durationMinimums.voiceArtistAudioDescription, 10) * 1000;
  return mediaFiles.some((file) => file.duration < minimumDuration);
}

function chooseForMeDurationTooShort(mediaFiles, durationMinimums, selectedTranslationType) {
  if (!selectedTranslationType) {
    return false;
  }
  const minimumDuration = parseInt(durationMinimums.chooseForMe, 10) * 1000;
  return mediaFiles.some((file) => file.duration < minimumDuration);
}

function aiDubbingTooShort(mediaFiles, selectedServices, durationMinimums) {
  const selectedDubbingOptions = selectedServices.find(
    (service) => service.serviceType === 'Dubbing'
  )?.orderOptions?.selectedDubbingOptions;
  if (!selectedDubbingOptions) {
    return false;
  }
  if (
    !selectedDubbingOptions.some(
      (option) =>
        option.dubbingType.toLowerCase() === 'ai' &&
        option.translationType.toLowerCase() === 'human'
    )
  ) {
    return false;
  }

  const minimumDuration = parseInt(durationMinimums.aiDubbing, 10) * 1000;
  return mediaFiles.some((file) => file.duration < minimumDuration);
}

function FinalizeOrder(props) {
  const { durationMinimums, displayBlockPricingNote, formAuthenticityToken } =
    useContext(ProjectContext);
  const orderContainsVoiceArtistAudioDescription = hasVoiceArtistAudioDescription(
    props.selectedServices
  );
  const [prices, setPrices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPricerate, setTotalPricerate] = useState(0);

  useEffect(() => {
    async function calculatePrices() {
      const temp = [];
      await Promise.all(
        props.mediaFiles.map(async (file) => {
          const priceInfo = await priceForAllServices(
            props.selectedServices,
            file.duration > 0 ? fileDuration(file) : null,
            props.sourceLanguage,
            formAuthenticityToken
          );
          temp.push({
            name: file.name,
            duration: file.duration > 0 ? fileDuration(file) : null,
            price: priceInfo.price,
            pricerate: priceInfo.pricerate,
          });
        })
      );
      setPrices(temp);
    }
    calculatePrices();
  }, []);

  useEffect(() => {
    setTotalPrice(prices.reduce((acc, file) => acc + file?.price || 0, 0));
    setTotalPricerate(prices.length > 0 ? prices[0].pricerate : 0);
  }, [prices]);

  useEffect(() => {
    userLogger.logEvent('NewOrder', 'Finalize Order Page', {});
  }, []);

  const violatedDurationMinimumVendors = [];
  if (
    orderContainsVoiceArtistAudioDescription &&
    voiceArtistWorkTooShort(props.mediaFiles, durationMinimums)
  ) {
    violatedDurationMinimumVendors.push('Captionmax');
  }
  if (
    durationUnderMinimum(props, durationMinimums) &&
    hasServiceWith3playMinimunDuration(props.selectedServices, props.defaultTranslationVendorId)
  ) {
    violatedDurationMinimumVendors.push('3Play');
  }
  if (props.showTransperfectDurationMinimum) {
    violatedDurationMinimumVendors.push('TransPerfect');
  }
  if (
    chooseForMeDurationTooShort(props.mediaFiles, durationMinimums, props.selectedTranslationType)
  ) {
    violatedDurationMinimumVendors.push(CHOOSE_FOR_ME_VENDOR);
  }
  if (aiDubbingTooShort(props.mediaFiles, props.selectedServices, durationMinimums)) {
    violatedDurationMinimumVendors.push('AI Dubbing');
  }

  return (
    <>
      {props.selectedServices.length > 0 && (
        <SelectedServicesPanel
          betaTermsAccepted={props.betaTermsAccepted}
          betaTermsDisplayDate={props.betaTermsDisplayDate}
          displayBetaTerms={props.displayBetaTerms}
          hasProofToFinalFile={props.mediaFiles.some((f) => f['proofToFinal'])}
          selectedServices={props.selectedServices}
          selectedTranslationType={props.selectedTranslationType}
          setBetaTermsAccepted={props.setBetaTermsAccepted}
          showWarnings={true}
        />
      )}

      <Card>
        <Card.Header className={css(styles.sectionHeader)}>Uploaded Files</Card.Header>
        {!noDuration(props.mediaFiles) &&
          violatedDurationMinimumVendors.map((vendor) => (
            <DurationMinimumNote
              durationMinimums={durationMinimums}
              hasVoiceArtistAudioDescription={orderContainsVoiceArtistAudioDescription}
              key={vendor}
              selectedServices={props.selectedServices}
              vendor={vendor}
            />
          ))}
        <Table className={css(styles.table)}>
          <thead>
            <tr>
              <th className={css(styles.tableHeader)}>File Name</th>
              <th className={css(styles.tableHeader)}>Duration</th>
              <th className={css(styles.tableHeader)}>Estimated Cost</th>
            </tr>
          </thead>
          <tbody>
            {noDuration(props.mediaFiles) && (
              <tr>
                <td colSpan={3}>
                  <Alert variant="warning">
                    Note: We could not determine the duration for any of your uploaded files. Your{' '}
                    {hasTranslation(props.selectedServices) || hasDubbing(props.selectedServices)
                      ? 'estimated'
                      : 'final'}{' '}
                    cost per minute for all selected services is{' '}
                    {numberToCurrency.format(totalPricerate)}
                    /min
                  </Alert>
                </td>
              </tr>
            )}
            {prices.map((file, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td>{file.name}</td>
                    <td>{file.duration ? durationToDisplay(file) : 'N/A'}</td>
                    <td>
                      &nbsp;
                      {file.duration ? (
                        numberToCurrency.format(file.price)
                      ) : (
                        <OverlayTrigger
                          placement="auto"
                          trigger={['hover', 'focus']}
                          delay={{ hide: 2000 }}
                          overlay={
                            <Popover>
                              <PopoverContent>
                                The cost for this file cannot be calculated because we are unable to
                                determine its duration.
                              </PopoverContent>
                            </Popover>
                          }
                        >
                          <i className={css(styles.icon) + ' fa fa-info-circle'}></i>
                        </OverlayTrigger>
                      )}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            {!noDuration(props.mediaFiles) && (
              <tr>
                <td>
                  <b>Final Subtotal</b>
                </td>
                <td>
                  <b>
                    {(missingDurations(props.mediaFiles) ? '>' : '') +
                      durationString(totalDuration(props.mediaFiles))}
                  </b>
                </td>
                <td>
                  <b>
                    {(hasChooseForMeAudioDescription(props.selectedServices) ||
                    missingDurations(props.mediaFiles)
                      ? '>'
                      : '') + numberToCurrency.format(totalPrice)}
                  </b>
                </td>
              </tr>
            )}
            <tr>
              {hasChooseForMeAudioDescription(props.selectedServices) && (
                <td colSpan={3}>
                  <b>*Note:</b>
                  Your final subtotal is calculated based on the standard Audio Description rate of
                  $9.00/min. This amount will increase if we determine that extended Audio
                  Description is more suitable for your videos.
                </td>
              )}
            </tr>
            {displayBlockPricingNote && (
              <tr>
                <td />
                <td />
                <td className={css(styles.muted)}>
                  *Estimated cost may change based on block pricing rates.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
}

FinalizeOrder.propTypes = {
  defaultTranslationVendorId: PropTypes.number,
  mediaFiles: PropTypes.arrayOf(PropTypes.object),
  selectedServices: selectedServicesType,
  selectedTranslationType: PropTypes.string,
  showTransperfectDurationMinimum: PropTypes.bool,
  sourceLanguage: PropTypes.number,
  displayBetaTerms: PropTypes.bool,
  betaTermsAccepted: PropTypes.bool,
  setBetaTermsAccepted: PropTypes.func,
  betaTermsDisplayDate: PropTypes.instanceOf(Date),
};

const styles = StyleSheet.create({
  muted: {
    color: '#000000',
    opacity: 0.6,
  },
  pricing: {
    'margin-left': 'auto',
  },
  sectionHeader: {
    backgroundColor: '#f1f1f1',
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  serviceHeader: {
    display: 'flex',
  },
  table: {
    marginBottom: '0rem',
  },
  tableHeader: {
    padding: '.75rem 1rem',
  },
  serviceType: {
    padding: '.5rem 1rem',
  },
  serviceDescription: {
    color: '#000000',
    opacity: 0.6,
  },
  serviceOptionPrice: {
    'margin-left': 'auto',
    float: 'right',
  },
});

export default FinalizeOrder;
