import React, { useEffect, useMemo, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import {
  aiDubbingOrderOptionType,
  liveUpgradeDetailsType,
  mixingOrderOptionsType,
} from '~/components/app/order_form/propTypes';

import AlignmentTipPanel from './AlignmentTipPanel';
import BroadcastScriptsInfoPanel from './BroadcastScriptsInfoPanel';
import FaqPanel from './FaqPanel';
import SelectedServicesPanel from './SelectedServicesPanel';
import StepContainer from './StepContainer';
import cheatSheetReducer, { CHEAT_SHEET_DEFAULT_STATE } from './cheatSheetReducer';
import warningReducer, { WARNING_DEFAULT_STATE } from './warningReducer';
import stepReducer from '~/components/app/order_form/steps/stepReducer';
import SuccessfulOrder from './SuccessfulOrder';
import TranslationVendorInfoPanel from './TranslationVendorInfoPanel';
import TranslationTypeInfoPanel from './TranslationTypeInfoPanel';

import {
  hasAiDubbing,
  hasBroadcastScripting,
  hasCaptionStyling,
  hasMediaLocalization,
  hasTransperfectTranscription,
  hasTransperfectTranslation,
  hasVoiceArtistAudioDescription,
  isVendorTranscriptionLanguage,
} from './helpers/selectedServicesHelpers';

import AdditionalServices from './steps/AdditionalServices';
import ChooseService from './steps/ChooseService';
import FinalizeOrder from './steps/FinalizeOrder';
import UploadFiles from './steps/UploadFiles';

import AlignmentErrorModal from '~/components/app/order_form/AlignmentErrorModal';
import LinkedAccountModal from './uploads/linked_accounts/LinkedAccountModal';
import LinksModal from './uploads/links/LinksModal';
import SampleFileModal from './uploads/sample/SampleFileModal';
import StartFileUploadModal from './uploads/StartFileUploadModal';
import StartCheatsheetUploadModal from './uploads/StartCheatsheetUploadModal';
import AddCheatSheet from '~/components/app/order_form/AddCheatSheet';

import {
  awsUploadFileQuery,
  translationOrderOptionsQuery,
  translationProfilesQuery,
  validateS3ObjectQuery,
} from '~/components/app/order_form/data/queries';
import { wordlistAssignmentQuery } from '~/components/app/wordlists/data/queries';
import {
  ADD,
  CLEAR,
  ENGLISH_ID,
  PRIMARY_SERVICES,
  REMOVE,
  S3_VALIDATION_DELAY,
  S3_VALIDATION_RETRIES,
  STEP_INDEX_ALL,
  STEP_INDEX_TRANSCRIPTION_ONLY,
  TRANSPERFECT_VENDOR_ID,
} from '~/helpers/constants';
import {
  checkForBlankTranscript,
  checkForIllegalQuestionMarks,
  repairSpeakerLabels,
} from '~/helpers/alignmentText';

import ErrorBoundary from '~/components/app/common/error_boundaries/ErrorBoundary';
import ErrorOverlay from '~/components/app/common/error_boundaries/ErrorOverlay';
import LoadingOverlay from '~/components/app/common/LoadingOverlay';

import { threeplayApi } from '~/logic/ThreeplayApi';
import { userLogger } from '~/logic/UserLogger';
import {
  awsDeleteMutation,
  submitOrderMutation,
  saveOrderTemplateMutation,
} from '~/components/app/order_form/data/mutations';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const ProjectContext = React.createContext();
function OrderForm({
  aiDubbingOrderOptions,
  accountTypeHash,
  descriptiveTranscriptPrices,
  dubbingOrderOptions,
  features,
  formAuthenticityToken,
  fileFormats,
  hasActivePricingBlocks,
  liveUpgradeDetails,
  mixingOrderOptions,
  orderableServices,
  orderTemplates,
  postOrderRedirect,
  postOrderRedirectUrl,
  resourceConfigurations,
  termsAcceptances,
}) {
  const defaultVendorId = TRANSPERFECT_VENDOR_ID;
  const stepIndex = features.other ? STEP_INDEX_ALL : STEP_INDEX_TRANSCRIPTION_ONLY;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [alignmentErrors, setAlignmentErrors] = useState(false);
  const [availableAdditionalServices, setAvailableAdditionalServices] = useState([]);
  const [availableTranslationOptionsForLanguages, setAvailableTranslationOptionsForLanguages] =
    useState({});
  const [batchError, setBatchError] = useState(false);
  const [batchName, setBatchName] = useState('My Latest Upload');
  const [batchId, setBatchId] = useState('');
  const [series, setSeries] = useState(null);
  const [betaTermsAccepted, setBetaTermsAccepted] = useState(false);
  const [cheatSheetState, cheatSheetDispatch] = useReducer(cheatSheetReducer, {
    orderCheatSheets: [],
    transcriptionService: CHEAT_SHEET_DEFAULT_STATE,
    audioDescriptionService: CHEAT_SHEET_DEFAULT_STATE,
    broadcastScriptingService: CHEAT_SHEET_DEFAULT_STATE,
    'Dubbings::Human::DubbingService': CHEAT_SHEET_DEFAULT_STATE,
    'DescriptiveTranscriptions::DescriptiveTranscriptService': CHEAT_SHEET_DEFAULT_STATE,
  });
  const [cheatsheetToUpload, setCheatsheetToUpload] = useState(null);
  const [currentStep, changeStep] = useReducer(stepReducer, {
    id: 0,
    type: 'primaryServices',
    stepIndex: stepIndex,
    usingTemplate: false,
  });
  const [defaultTranslationProfileId, setDefaultTranslationProfileId] = useState(null);
  const [defaultTranslationVendorId, setDefaultTranslationVendorId] = useState(defaultVendorId);
  const [errors, setErrors] = useState('');
  const [integrations, setIntegrations] = useState([]);
  const [integrationPlatformDetails, setIntegrationPlatformDetails] = useState({});
  const [loadedTemplate, setLoadedTemplate] = useState(null);
  const [orderID, setOrderID] = useState(null);
  const [orderMediaFiles, setOrderMediaFiles] = useState([]);
  const [refusedSourceLanguageTemplate, setRefusedSourceLanguageTemplate] = useState(false);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(0);
  const [selectedTranslationLanguages, setSelectedTranslationLanguages] = useState([]);
  const [selectedTranslationType, setSelectedTranslationType] = useState('');
  const [acceptedWarningsState, acceptedWarningsDispatch] = useReducer(
    warningReducer,
    WARNING_DEFAULT_STATE
  );
  const [showAlignmentErrorModal, setShowAlignmentErrorModal] = useState(false);
  const [showLinkedAccountModal, setShowLinkedAccountModal] = useState(false);
  const [showLinksModal, setShowLinksModal] = useState(false);
  const [showSampleFileModal, setShowSampleFileModal] = useState(false);
  const [showStartCheatsheetUploadModal, setShowStartCheatsheetUploadModal] = useState(false);
  const [showStartFileUploadModal, setShowStartFileUploadModal] = useState(false);
  const [showTransperfectDurationMinimum, setShowTransperfectDurationMinimum] = useState(false);
  const [sourceLanguage, setSourceLanguage] = useState([ENGLISH_ID]);
  const [submitErrors, setSubmitErrors] = useState([]);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [successfulOrder, setSuccessfulOrder] = useState(false);
  const [templateErrors, setTemplateErrors] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [templateSaved, setTemplateSaved] = useState(false);
  const [translationOrderOptions, setTranslationOrderOptions] = useState([]);
  const [translationTypes, setTranslationTypes] = useState([]);
  const [translationProfiles, setTranslationProfiles] = useState([]);
  const [translationSelectedOrderOptions, setTranslationSelectedOrderOptions] = useState([]);
  const [translationVendorForInfo, setTranslationVendorForInfo] = useState(defaultVendorId);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});
  const [validFileUploads, setValidFileUploads] = useState({});
  const [validatingUploads, setValidatingUploads] = useState(false);
  const [validDubbingQuote, setValidDubbingQuote] = useState(false);
  const [validTranslationOrder, setValidTranslationOrder] = useState(false);
  const [vendorTranscriptionVendor, setVendorTranscriptionVendor] = useState(defaultVendorId);
  const [wordlistAssignment, setWordlistAssignment] = useState(null);

  const { difficultySurcharge, ...durationMinimums } = resourceConfigurations;

  // Set Alignment Errors useMemo
  useMemo(() => {
    const orderedAlignment =
      selectedServices.filter((service) => service.serviceType === 'Alignment').length > 0;
    if (orderedAlignment) {
      setAlignmentErrors(
        orderMediaFiles.filter(
          (mediaFile) =>
            mediaFile.alignmentText === undefined || mediaFile.alignmentText?.errors.length > 0
        ).length > 0
      );
    }
  }, [orderMediaFiles, selectedServices]);

  // Translation useEffect
  useEffect(() => {
    let containsTransperfectOrder = false;
    const translationService = selectedServices.filter(
      (service) => service.serviceType === 'Translation'
    );
    if (translationService.length > 0) {
      if (translationService[0].orderOptions?.selectedOptions?.length > 0) {
        setValidTranslationOrder(true);
        if (hasTransperfectTranslation(translationService)) {
          containsTransperfectOrder = true;
        }
      }
    }
    if (hasTransperfectTranscription(selectedServices, defaultTranslationVendorId)) {
      containsTransperfectOrder = true;
    }
    setShowTransperfectDurationMinimum(containsTransperfectOrder);
  }, [selectedServices, defaultTranslationVendorId]);

  // Set Allowed Cheat Sheets useEffect
  useEffect(() => {
    const orderContainsCaptionStyling = hasCaptionStyling(selectedServices);
    const orderContainsVoiceArtistAD = hasVoiceArtistAudioDescription(selectedServices);

    cheatSheetDispatch({
      type: orderContainsCaptionStyling ? 'disableUpload' : 'enableUpload',
      serviceType: 'transcriptionService',
    });

    cheatSheetDispatch({
      type: orderContainsVoiceArtistAD ? 'disableUpload' : 'enableUpload',
      serviceType: 'audioDescriptionService',
    });

    if (orderContainsVoiceArtistAD || orderContainsCaptionStyling) {
      setCheatsheetToUpload(null);
    }
  }, [selectedServices]);

  // Validate S3 Upload useEffect
  useEffect(() => {
    if (orderMediaFiles.length === 0 || !localFileUpload()) {
      return;
    }

    const allFilesUploaded = orderMediaFiles.every((file) => {
      return uploadComplete[file.sourceS3File.key] === 'complete';
    });

    if (allFilesUploaded && !validatingUploads) {
      setValidatingUploads(true);
      setTimeout(
        () =>
          orderMediaFiles.forEach((file) => {
            const bucket = file.sourceS3File.bucket;
            const key = file.sourceS3File.key;
            validateS3Upload(bucket, key, S3_VALIDATION_RETRIES);
          }),
        S3_VALIDATION_DELAY
      );
    }
  }, [uploadComplete]);

  // Finish Validating Uploads useEffect
  useEffect(() => {
    if (allFilesValidated()) {
      setValidatingUploads(false);
    }
  }, [validFileUploads]);

  // Auto Add Transcription to Transcription Only useEffect
  useEffect(() => {
    if (!!features.transcription && !features.other && selectedServices.length === 0) {
      setSelectedServices([
        {
          description:
            'Order transcripts and captions in a variety of languages and turnaround levels.',
          displayName: 'Transcription',
          orderOptions: {},
          price: {},
          primary: true,
          serviceType: 'Transcription',
        },
      ]);
    }
  }, [features, selectedServices.length]);

  // Get Wordlist Assignment useEffect
  useEffect(() => {
    getWordlistAssignment();
  }, [batchId]);

  // Load Template useEffect
  useEffect(() => {
    if (loadedTemplate === null) {
      return;
    }

    setBatchId(loadedTemplate.batch.id);
    setBatchName(loadedTemplate.batch.name);
    setSeries(loadedTemplate.series);
    setSelectedServices(loadedTemplate.services);

    if (loadedTemplate.instructions) {
      cheatSheetDispatch({ type: 'loadTemplate', instructions: loadedTemplate.instructions });
    }

    changeStep({ type: 'templateJump' });
  }, [loadedTemplate, features]);

  function allFilesValidated() {
    const omfKeys = orderMediaFiles.map((file) => {
      return file.sourceS3File.key;
    });
    const vfuKeys = Object.keys(validFileUploads).sort();
    return JSON.stringify(omfKeys) === JSON.stringify(vfuKeys);
  }

  function validateS3Upload(bucket, key, retries) {
    threeplayApi.request(validateS3ObjectQuery, { bucket, key }).then((response) => {
      if (retries > 1 && !response.data.validateS3Object) {
        setTimeout(() => validateS3Upload(bucket, key, retries - 1), S3_VALIDATION_DELAY);
      } else {
        setValidFileUploads((prevState) => ({
          ...prevState,
          [key]: response.data.validateS3Object,
        }));
        if (!response.data.validateS3Object) {
          setUploadProgress((prevState) => ({ ...prevState, [key]: 100 }));
          setValidFileUploads((prevState) => ({ ...prevState, [key]: true }));
          userLogger.logEvent('NewOrder', 'S3 source upload missing', {
            bucket: bucket,
            key: key,
          });
        }
      }
    });
  }

  useEffect(() => {
    if (selectedServices.length === 0) {
      return;
    }

    const primaryService = selectedServices.find((s) => s.primary === true);
    const primaryServiceLanguage = (primaryService.orderOptions &&
      primaryService.orderOptions.language &&
      primaryService.orderOptions.language.ids) || [ENGLISH_ID];

    if (primaryServiceLanguage === sourceLanguage) {
      return;
    }
    setSourceLanguage(primaryServiceLanguage);
  }, [selectedServices]);

  function updateOrderOptions(serviceType, orderOption) {
    const newSelectedServices = [...selectedServices];
    const serviceIndex = newSelectedServices.findIndex(
      (service) => service.serviceType === serviceType
    );
    if (serviceIndex !== -1) {
      newSelectedServices[serviceIndex] = {
        ...newSelectedServices[serviceIndex],
        orderOptions: orderOption,
      };
    }
    setSelectedServices(newSelectedServices);
  }

  function localFileUpload() {
    return orderMediaFiles.length > 0 && orderMediaFiles[0].hasOwnProperty('sourceS3File');
  }

  useEffect(() => {
    threeplayApi.request(translationProfilesQuery).then((response) => {
      const data = response.data || {};
      const profiles = data.project?.translationProfiles || [];
      setTranslationProfiles(profiles);
      if (profiles.length > 0) {
        const defaultTranslationProfile = profiles.find((tp) => tp.default === true);
        if (defaultTranslationProfile) {
          setDefaultTranslationProfileId(defaultTranslationProfile.id);
        } else {
          setDefaultTranslationProfileId(profiles[0].id);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedServices.length === 0) {
      return;
    }

    const selectedPrimaryService = selectedServices.filter((service) => {
      return PRIMARY_SERVICES.includes(service.serviceType);
    })[0];
    const translationOrderingAvailable =
      availableAdditionalServices &&
      availableAdditionalServices.filter((service) => service.serviceType === 'Translation')
        .length === 1;

    threeplayApi
      .request(translationOrderOptionsQuery, {
        primaryServiceType: selectedPrimaryService.serviceType,
        selectedAdditionalServiceType: 'Translation',
        sourceLanguageId: sourceLanguage[0],
      })
      .then((response) => {
        const data = response.data || {};
        setVendorTranscriptionVendor(Number(data.project.defaultVendorTranscriptionVendorId));

        if (translationOrderingAvailable) {
          const options =
            data.project &&
            data.project.orderableServices[0].additionalServices.find(
              ({ serviceType }) => serviceType == 'Translation'
            )?.orderOptions?.translationOrderOptions;
          const translationTypes =
            data.project &&
            data.project.orderableServices[0].additionalServices.find(
              ({ serviceType }) => serviceType == 'Translation'
            )?.orderOptions?.translationServiceLevelTypes;
          setDefaultTranslationVendorId(data.project.defaultTranslationVendorId);
          setTranslationVendorForInfo(Number(data.project.defaultTranslationVendorId));
          setTranslationOrderOptions(options);
          setTranslationTypes(translationTypes);
        }
      });
  }, [sourceLanguage]);

  function updateRejectedFiles(newFiles) {
    setRejectedFiles(rejectedFiles.concat(newFiles));
  }

  function removeFromUploadList(index) {
    const mediaFiles = [...orderMediaFiles];
    const fileToBeRemoved = mediaFiles.splice(index, 1);
    if (fileToBeRemoved[0].sourceS3File) {
      const newUploadProgress = { ...uploadProgress };
      const newValidFileUploads = { ...validFileUploads };
      const bucket = fileToBeRemoved[0].sourceS3File.bucket;
      const key = fileToBeRemoved[0].sourceS3File.key;
      deleteS3File(bucket, key);
      delete newUploadProgress[fileToBeRemoved[0].sourceS3File.key];
      setUploadProgress(newUploadProgress);

      delete newValidFileUploads[fileToBeRemoved[0].sourceS3File.key];
      setValidFileUploads(newValidFileUploads);
    }
    setOrderMediaFiles(mediaFiles);
  }

  function removeFromCheatsheetUploadList(serviceType) {
    setCheatsheetToUpload(null);
    cheatSheetDispatch({ type: 'remove', serviceType: serviceType });
  }

  // Takes the current shape of data (see AudioDescriptionMixSection) and condenses to
  // {
  //   fiveOne: ['embedded'],
  //   mono: [],
  //   stereo: ['voiceover', 'embedded'],
  // }
  const simplifyOutputAssetOptions = (outputAssetOptions) => {
    if (outputAssetOptions === undefined) {
      return undefined;
    }

    return Object.keys(outputAssetOptions).reduce((accumulator, value) => {
      return {
        ...accumulator,
        [value]: outputAssetOptions[value].embedSelections.map((s) => s.code),
      };
    }, {});
  };

  function moldFormat(orderedServices) {
    const servicesArray = [];
    orderedServices.forEach((service) => {
      const newServiceObject = {};
      const newOrderObject = {};
      newServiceObject.type = service.serviceType;
      newServiceObject['betaTermsAccepted'] = betaTermsAccepted;
      if (service.serviceType === 'Transcription') {
        newOrderObject['languageIds'] = service['orderOptions'].language.ids;
        if (isVendorTranscriptionLanguage(newOrderObject['languageIds'])) {
          newServiceObject.type = 'VendorTranscription';
        }
        newOrderObject['turnaroundLevelId'] = parseInt(service['orderOptions'].turnaroundLevel.id);
        newOrderObject['captionStyling'] = service['orderOptions'].captionStyling?.name;
        newServiceObject['transcriptionOptions'] = newOrderObject;
      }
      if (service.serviceType === 'AudioDescription') {
        newOrderObject['level'] = service['orderOptions'].serviceLevel.name;
        newOrderObject['turnaroundLevelId'] = parseInt(service['orderOptions'].turnaroundLevel.id);
        newOrderObject['speakerType'] = service['orderOptions'].speakerType?.name;
        newOrderObject['outputAssetOptions'] = simplifyOutputAssetOptions(
          service['orderOptions'].outputAssetOptions
        );
        newServiceObject['audioDescriptionOptions'] = newOrderObject;
      }
      if (service.serviceType === 'CaptionPlacement') {
        newOrderObject['level'] = service['orderOptions'].serviceLevel.name;
        newServiceObject['captionPlacementOptions'] = newOrderObject;
      }
      if (service.serviceType === 'Translation') {
        const newTranslationOrderObject = service['orderOptions']['selectedOptions'].map(
          (orderOption) => ({
            targetLanguage: orderOption.targetLanguage,
            translationProfileId: parseInt(orderOption.translationProfile),
            translationVendorId: parseInt(orderOption.vendor.id),
            translationServiceLevelId: parseInt(orderOption.serviceLevel.id),
          })
        );
        newServiceObject['translationOptions'] = newTranslationOrderObject;
      }
      if (service.serviceType === 'BroadcastScript') {
        const newBroadcastScriptOrderObject = service['orderOptions']['scriptFormats'].map(
          (format) => ({
            orderOptionId: parseInt(format.id),
          })
        );
        newServiceObject['broadcastScriptOptions'] = newBroadcastScriptOrderObject;
      }
      if (service.serviceType === 'Dubbing') {
        const newDubbingOrderObject = service['orderOptions']['selectedDubbingOptions'].map(
          (orderOption) => ({
            dubbingOrderOptionId: orderOption.id,
            dubbingType: orderOption.dubbingType,
            // Relevant for Human Dubbing
            quoteUrl: service['orderOptions']['quoteUrl'],
            embedding: !!service['orderOptions']['embedding'],
            // Relevant for AI Dubbing
            translationType: orderOption.translationType,
            translationProfileId: parseInt(orderOption.translationProfileID),
          })
        );
        newServiceObject['dubbingOptions'] = newDubbingOrderObject;
      }
      servicesArray.push(newServiceObject);
    });
    return servicesArray;
  }

  function setAlignmentTexts(index, text) {
    //merge in alignmentText into orderMediaFiles
    text = repairSpeakerLabels(text);

    const blankError = checkForBlankTranscript(text);
    const illegalChars = checkForIllegalQuestionMarks(text);
    const errors = blankError.concat(illegalChars);

    const newFilesToUpload = [
      ...orderMediaFiles.slice(0, index),
      Object.assign({}, orderMediaFiles[index], { alignmentText: { text: text, errors: errors } }),
      ...orderMediaFiles.slice(index + 1),
    ];
    setOrderMediaFiles(newFilesToUpload);
  }

  async function saveTemplate() {
    const services = moldFormat(selectedServices);
    const instructions = cheatSheetState.orderCheatSheets;
    const templateDetails = {
      name: templateName,
      batch: batchName,
      seriesId: series?.id ?? null,
      languages: sourceLanguage,
      services: services,
      instructions: instructions,
    };
    const response = await threeplayApi.request(saveOrderTemplateMutation, {
      template: templateDetails,
    });

    if (response.errors) {
      setTemplateSaved(false);
    } else if (response.data?.createOrderTemplate) {
      if (response.data.createOrderTemplate.success) {
        setTemplateSaved(true);
        setTemplateErrors([]);
      } else {
        setTemplateSaved(false);
        setTemplateErrors(response.data.createOrderTemplate.errors);
      }
    }
  }

  async function submit() {
    setSubmittingForm(true);
    const orderServices = moldFormat(selectedServices);
    const cheatSheets = cheatSheetState.orderCheatSheets.map(
      ({ name, sourceS3File, serviceType, content }) => ({
        name,
        sourceS3File,
        serviceType,
        content,
      })
    );
    const mediaFiles = orderMediaFiles.map(
      ({ name, proofToFinal, sourceLinkedAccountVideoFile, sourceS3File, sourceUrl, words }) => ({
        batchName,
        cheatSheets,
        seriesId: series?.id || null,
        name,
        proofToFinal: accountTypeHash.studio && proofToFinal,
        sourceLanguage,
        sourceLinkedAccountVideoFile,
        sourceS3File,
        sourceUrl,
        words,
      })
    );
    mediaFiles.forEach(function (value, index) {
      mediaFiles[index]['alignmentText'] = orderMediaFiles[index].alignmentText?.text || '';
      mediaFiles[index]['liveFileId'] = liveUpgradeDetails?.liveFileId;
    });

    const orderInput = {
      mediaFiles: mediaFiles,
      services: orderServices,
    };
    const response = await threeplayApi.request(submitOrderMutation, { order: orderInput });

    if (response.errors) {
      setErrors(response.errors);
      setSubmittingForm(false);
    } else if (response.data?.orderServices) {
      if (response.data.orderServices.errors) {
        setSubmitErrors(response.data.orderServices.errors.map((error) => error.message));
        setSubmittingForm(false);
      } else {
        setSubmittingForm(false);
        setSuccessfulOrder(true);
        setOrderID(response.data.orderServices?.order);
        const transcription = orderServices.find((s) => s.type === 'Transcription');
        const audioDescription = orderServices.find((s) => s.type === 'AudioDescription');
        const captionPlacement = orderServices.find((s) => s.type === 'CaptionPlacement');

        const transcriptionTurnaround =
          (transcription &&
            transcription.transcriptionOptions &&
            transcription.transcriptionOptions.turnaroundLevelId) ||
          null;
        const audioDescriptionTurnaround =
          (audioDescription &&
            audioDescription.audioDescriptionOptions &&
            audioDescription.audioDescriptionOptions.turnaroundLevelId) ||
          null;
        const audioDescriptionLevel =
          (audioDescription &&
            audioDescription.audioDescriptionOptions &&
            audioDescription.audioDescriptionOptions.level) ||
          null;
        const captionPlacementLevel =
          (captionPlacement &&
            captionPlacement.captionPlacementOptions &&
            captionPlacement.captionPlacementOptions.level) ||
          null;

        userLogger.logEvent('NewOrder', 'Order Placed', {
          'File Count': orderInput.mediaFiles.length,
          'Service Types': orderServices.map((s) => s.type),
          'Source Language': sourceLanguage,
          'Transcription Turnaround': transcriptionTurnaround,
          'Audio Description Turnaround': audioDescriptionTurnaround,
          'Audio Description Service Level': audioDescriptionLevel,
          'Caption Placement Service Level': captionPlacementLevel,
        });
      }
    }
  }

  function openStartFileUploadModal(acceptedFiles) {
    setAcceptedFiles(acceptedFiles);
    setShowStartFileUploadModal(true);
  }

  function openLinkedAccountModal(linkedAccount) {
    const platformName = linkedAccount.platformDetails.name;
    const supportSearching = linkedAccount.platformDetails.supportsSearching;
    setIntegrations(linkedAccount.integrations);
    const platformDetails = {
      name: platformName,
      supportSearching: supportSearching,
    };
    setIntegrationPlatformDetails(platformDetails);
    setShowLinkedAccountModal(true);
  }

  function openStartCheatsheetUploadModal(newCheatsheet, serviceType) {
    setCheatsheetToUpload({ serviceType: serviceType, file: newCheatsheet[0] });
    setShowStartCheatsheetUploadModal(true);
  }

  async function startUpload() {
    setShowStartFileUploadModal(false);
    setUploading(true);
    setDurationAndUpload(acceptedFiles);
    userLogger.logEvent('NewOrder', 'Start Upload', {
      'File Source': 'Computer',
      'File count': acceptedFiles.length,
    });
  }

  async function setDurationAndUpload(files) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      // For each file get the AWS signature and upload
      const awsData = await fetchUploadData({ file: file });
      const sourceS3File = { bucket: awsData.bucket, key: awsData.key };
      const mediaFileInfo = {
        name: file.name.replace(/\.[^.]*$/, ''),
        size: file.size,
        sourceS3File: sourceS3File,
        words:
          wordlistAssignment && wordlistAssignment.wordlist && wordlistAssignment.wordlist.words,
      };
      setOrderMediaFiles((prevState) => prevState.concat(mediaFileInfo));
      // For each file, get the duration
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = async function () {
        window.URL.revokeObjectURL(video.src);
        // In case metadata is corrupted and doesn't contain duration
        let duration = null;
        if (video.duration) {
          duration = video.duration * 1000;
        }
        setOrderMediaFiles((prevState) =>
          prevState.map((f) => (f.sourceS3File === sourceS3File ? { ...f, duration } : f))
        );
      };
      uploadFileToS3(awsData, file);
    }
  }

  async function startCheatsheetUpload() {
    setShowStartCheatsheetUploadModal(false);
    setUploading(true);
    const file = cheatsheetToUpload.file;
    const time = new Date().getTime();
    const awsData = await fetchUploadData({
      file: file,
      type: 'cheatsheet',
      keyPrefix: `_cheat_sheets/${cheatsheetToUpload.serviceType}-${time}-`,
    });
    const sourceS3File = { bucket: awsData.bucket, key: awsData.key };
    const cheatsheetInfo = {
      name: file.name,
      size: file.size,
      sourceS3File: sourceS3File,
      serviceType: cheatsheetToUpload.serviceType,
    };
    cheatSheetDispatch({
      type: 'upload',
      serviceType: cheatsheetInfo.serviceType,
      info: cheatsheetInfo,
    });
    uploadFileToS3(awsData, file);
    setCheatsheetToUpload(null);
  }

  async function fetchUploadData({ file = file, type = 'newUpload', keyPrefix = '' }) {
    const response = await threeplayApi.request(awsUploadFileQuery, {
      fileName: file.name,
      type: type,
      keyPrefix: keyPrefix,
    });
    return response.data.project.awsUpload;
  }

  function deleteS3File(bucket, key) {
    threeplayApi.request(awsDeleteMutation, { bucket: bucket, key: key });
  }

  function uploadFileToS3(awsData, file) {
    setUploadComplete((prevState) => ({ ...prevState, [awsData.key]: 'uploading' }));

    const payload = buildFormData(awsData, file);
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', uploadProgressBar.bind(null, awsData.key), false);
    xhr.upload.addEventListener('load', uploadCompleteHandler.bind(null, awsData.key), false);
    xhr.upload.addEventListener('abort', uploadCanceled.bind(null, awsData.key), false);
    xhr.open('POST', awsData.amazonUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
        const errorDetails = {
          status: xhr.status,
          statusText: xhr.statusText,
          response: xhr.responseText,
        };
        userLogger.logEvent('NewOrder', 'S3 source upload error', {
          bucket: awsData.bucket,
          key: awsData.key,
          errorDetails: errorDetails,
        });
        uploadFailed(awsData.key);
      }
    };
    xhr.send(payload);
  }

  function uploadProgressBar(fileKey, evt) {
    if (evt.lengthComputable) {
      const percentComplete = Math.round((evt.loaded * 100) / evt.total);
      setUploadProgress((prevState) => ({ ...prevState, [fileKey]: percentComplete }));
    }
  }

  function uploadCompleteHandler(fileKey) {
    setUploadComplete((prevState) => ({ ...prevState, [fileKey]: 'complete' }));
  }

  // Failed - Show error message
  function uploadFailed(fileKey) {
    setUploadProgress((prevState) => ({ ...prevState, [fileKey]: -1 }));
    setUploadComplete((prevState) => ({ ...prevState, [fileKey]: 'error' }));
  }

  // Cancelled - Show error message
  function uploadCanceled(fileKey) {
    setUploadProgress((prevState) => ({ ...prevState, [fileKey]: -1 }));
    setUploadComplete((prevState) => ({ ...prevState, [fileKey]: 'error' }));
  }

  function buildFormData(awsData, file) {
    const formData = new FormData();
    formData.append('key', awsData.key);
    formData.append('AWSAccessKeyId', awsData.accessKey);
    formData.append('acl', 'private');
    formData.append('policy', awsData.policy);
    formData.append('signature', awsData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', file);
    return formData;
  }

  function setLinkedFilesToUpload(type, videoData) {
    switch (type) {
      case ADD: {
        videoData.words =
          wordlistAssignment && wordlistAssignment.wordlist && wordlistAssignment.wordlist.words;
        setOrderMediaFiles((prevState) => prevState.concat(videoData));
        break;
      }
      case REMOVE: {
        const updatedList = orderMediaFiles.filter(
          (vid) =>
            vid['sourceLinkedAccountVideoFile']['externalId'] !==
            videoData['sourceLinkedAccountVideoFile']['externalId']
        );
        setOrderMediaFiles(updatedList);
        break;
      }
      case CLEAR: {
        setOrderMediaFiles([]);
        break;
      }
    }
  }

  function getWordlistAssignment() {
    threeplayApi.request(wordlistAssignmentQuery, { batchId: batchId }).then((response) => {
      if (response.data.wordlistAssignment) {
        setWordlistAssignment(response.data.wordlistAssignment);
      }
    });
  }

  function determineCurrentStepErrors() {
    const errors = [];
    const audioDescriptionOrder = selectedServices.find(
      (service) => service.serviceType === 'AudioDescription'
    );
    const broadcastScriptOrder = selectedServices.find(
      (service) => service.serviceType === 'BroadcastScript'
    );
    const translationOrder = selectedServices.filter(
      (service) => service.serviceType === 'Translation'
    );
    const dubbingOrder = selectedServices.find((service) => service.serviceType === 'Dubbing');
    switch (currentStep.type) {
      case 'primaryServices':
        if (cheatSheetState.transcriptionService.tooLong) {
          errors.push(
            'Your cheatsheet text is too long. Please remove excess characters before proceeding'
          );
        } else if (selectedServices.length === 0) {
          errors.push('Please select a main service');
        }
        return errors;
      case 'secondaryServices':
        if (cheatSheetState.audioDescriptionService.tooLong) {
          errors.push(
            'Your audio description cheatsheet text is too long. Please remove excess characters before proceeding'
          );
        }
        if (translationOrder.length > 0) {
          if (!validTranslationOrder) {
            errors.push('Please select one or more output languages for translation');
          }
          if (!acceptedWarningsState.translation) {
            errors.push('Please accept the translations terms');
          }
        }
        if (audioDescriptionOrder?.orderOptions?.speakerType?.name === 'Voice Artist') {
          if (!acceptedWarningsState.voiceArtistAD) {
            errors.push('Please accept the voice artist audio description terms');
          }
          if (!hasRelevantAudioMixingSelections(audioDescriptionOrder)) {
            errors.push('Please choose audio mix and embed options');
          }
        }
        if (broadcastScriptOrder && !acceptedWarningsState.broadcastScripts) {
          errors.push('Please accept the broadcast script terms');
        }
        if (dubbingOrder) {
          if ((dubbingOrder?.orderOptions?.selectedDubbingOptions?.length || 0) === 0) {
            errors.push('Please select one or more languages for dubbing');
          }
          if (accountTypeHash.studio && !validDubbingQuote) {
            errors.push('Please add a valid dubbing quote');
          }
          if (!accountTypeHash.studio && !acceptedWarningsState.dubbing) {
            errors.push('Please accept the dubbing terms');
          }
        }
        return errors;
      case 'upload':
        if (batchError) {
          errors.push('Please enter a valid folder name or select one before continuing');
        }
        if (orderMediaFiles.length === 0) {
          errors.push('Please add or upload at least one file');
        }
        if (!!liveUpgradeDetails && orderMediaFiles.length > 1) {
          errors.push('Live upgrades can only contain one file');
        }
        if (localFileUpload()) {
          if (validatingUploads) {
            errors.push('Please wait for your files to finish processing before continuing');
          } else if (
            allFilesValidated() &&
            !orderMediaFiles.every((file) => {
              return validFileUploads[file.sourceS3File.key] === true;
            })
          ) {
            errors.push(
              'One of more of your files have failed during the upload process. Please remove and retry before continuing'
            );
          } else if (
            !orderMediaFiles.every((file) => {
              return uploadProgress[file.sourceS3File.key] === 100;
            })
          ) {
            errors.push('Please wait for your files to finish uploading before continuing');
          }
        }
        return errors;
      case 'finalize':
        if (displayBetaTerms() && !betaTermsAccepted) {
          errors.push('Please accept the beta terms of service to finish placing your order');
        }
        return errors;
    }
  }

  const hasRelevantAudioMixingSelections = (audioDescriptionOrder) => {
    // If it's not VAAD, it doesn't apply
    if (audioDescriptionOrder?.orderOptions?.speakerType?.name !== 'Voice Artist') {
      return true;
    }

    // If the account is not Studio, it doesn't apply
    if (!accountTypeHash.studio) {
      return true;
    }

    if (audioDescriptionOrder?.orderOptions?.outputAssetOptions === undefined) {
      return false;
    }

    let globalOptionValidity = false;
    const individualOptionValidity = Object.values(
      audioDescriptionOrder?.orderOptions?.outputAssetOptions
    ).map((option) => {
      if (option === undefined) {
        // Not selected
        return true;
      }

      if (option.embedSelections.length === 0) {
        // Selected, but no asset selected.
        return false;
      }

      globalOptionValidity = true;
      return true;
    });
    return globalOptionValidity && individualOptionValidity.every((el) => el === true);
  };

  function displayBetaTerms() {
    return mustAcceptAiDubbingTerms();
  }

  function betaTermsDisplayDate() {
    const dates = [];
    if (mustAcceptAiDubbingTerms()) {
      dates.push(termsAcceptances['aiDubbing']['lastUpdated']);
    }

    if (dates.length === 0) {
      return new Date(); // Probably won't be needed, but better to have a date than an empty string / null
    }

    let latestDate = new Date(dates[0]);
    for (const dateString of dates) {
      const date = new Date(dateString);
      if (date.getTime() > latestDate.getTime()) {
        latestDate = date;
      }
    }

    return latestDate;
  }

  const mustAcceptAiDubbingTerms = () => {
    return (
      hasAiDubbing(selectedServices) &&
      'aiDubbing' in termsAcceptances &&
      termsAcceptances['aiDubbing']['accepted'] === false
    );
  };

  function cheatSheetComponent(csServiceType, opts = {}) {
    return liveUpgradeDetails ? (
      <>Your wordlists and speaker labels will be carried over from the original live event.</>
    ) : (
      <AddCheatSheet
        cheatSheetDispatch={cheatSheetDispatch}
        cheatSheetState={cheatSheetState}
        cheatsheetToUpload={cheatSheetState.orderCheatSheets.find(
          (cs) => cs.serviceType === csServiceType
        )}
        openStartCheatsheetUploadModal={openStartCheatsheetUploadModal}
        removeFromCheatsheetUploadList={removeFromCheatsheetUploadList}
        serviceType={csServiceType}
        uploading={uploading}
        uploadProgress={uploadProgress}
        showVoiceArtistAudioDescriptionNote={opts['showVoiceArtistAudioDescriptionNote']}
      />
    );
  }

  if (submittingForm) {
    return <LoadingOverlay message="Processing your order " />;
  } else if (successfulOrder) {
    return (
      <SuccessfulOrder
        orderID={orderID}
        postOrderRedirect={postOrderRedirect}
        postOrderRedirectUrl={postOrderRedirectUrl}
      />
    );
  } else if (errors.length > 0) {
    return (
      <ErrorOverlay
        messages={['Error: Please refresh the page and contact support if the problem persists.']}
      />
    );
  } else if (submitErrors.length > 0) {
    return <ErrorOverlay messages={submitErrors} />;
  } else {
    return (
      <ProjectContext.Provider
        value={{
          accountType: accountTypeHash,
          aiDubbingOrderOptions: aiDubbingOrderOptions,
          descriptiveTranscriptPrices: descriptiveTranscriptPrices,
          difficultySurcharge: difficultySurcharge,
          displayBlockPricingNote: hasActivePricingBlocks,
          dubbingOrderOptions: dubbingOrderOptions,
          durationMinimums: durationMinimums,
          features: features,
          fileFormats: fileFormats,
          formAuthenticityToken: formAuthenticityToken,
          liveUpgradeDetails: liveUpgradeDetails,
          loadedTemplate: loadedTemplate,
          mixingOrderOptions: mixingOrderOptions,
          orderTemplates: orderTemplates,
          orderableServices: orderableServices,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary component="OrderForm">
            <Container fluid>
              <Row>
                <Col sm={8}>
                  <StepContainer
                    alignmentErrors={alignmentErrors}
                    currentStep={currentStep}
                    enableNext={determineCurrentStepErrors().length === 0}
                    selectedServices={selectedServices}
                    changeStep={changeStep}
                    setShowAlignmentErrorModal={setShowAlignmentErrorModal}
                    submit={submit}
                    stepErrors={determineCurrentStepErrors()}
                  >
                    {currentStep.type === 'primaryServices' && (
                      <ChooseService
                        cheatSheetComponent={cheatSheetComponent}
                        selectedServices={selectedServices}
                        selectedTemplate={selectedTemplate}
                        series={series}
                        setAvailableAdditionalServices={setAvailableAdditionalServices}
                        setLoadedTemplate={setLoadedTemplate}
                        setSelectedServices={setSelectedServices}
                        setSelectedTemplate={setSelectedTemplate}
                        setSeries={setSeries}
                        showSeriesForm={features.mediaFileSeries}
                        transcriptionVendorId={vendorTranscriptionVendor}
                        updateOrderOptions={updateOrderOptions}
                      />
                    )}
                    {currentStep.type === 'secondaryServices' && (
                      <AdditionalServices
                        availableAdditionalServices={availableAdditionalServices}
                        availableTranslationOptionsForLanguages={
                          availableTranslationOptionsForLanguages
                        }
                        cheatSheetComponent={cheatSheetComponent}
                        defaultTranslationProfileId={defaultTranslationProfileId}
                        defaultTranslationVendorId={defaultTranslationVendorId.toString()}
                        refusedSourceLanguageTemplate={refusedSourceLanguageTemplate}
                        selectedServices={selectedServices}
                        selectedTranslationLanguages={selectedTranslationLanguages}
                        selectedTranslationType={selectedTranslationType}
                        setRefusedSourceLanguageTemplate={setRefusedSourceLanguageTemplate}
                        setSelectedServices={setSelectedServices}
                        setTranslationVendorForInfo={setTranslationVendorForInfo}
                        setValidDubbingQuote={setValidDubbingQuote}
                        sourceLanguage={sourceLanguage}
                        translationOrderOptions={translationOrderOptions}
                        translationProfiles={translationProfiles}
                        translationSelectedOrderOptions={translationSelectedOrderOptions}
                        translationTypes={translationTypes}
                        setAvailableTranslationOptionsForLanguages={
                          setAvailableTranslationOptionsForLanguages
                        }
                        setSelectedTranslationLanguages={setSelectedTranslationLanguages}
                        setTranslationSelectedOrderOptions={setTranslationSelectedOrderOptions}
                        setSelectedTranslationType={setSelectedTranslationType}
                        updateOrderOptions={updateOrderOptions}
                        validDubbingQuote={validDubbingQuote}
                        acceptedWarningsState={acceptedWarningsState}
                        acceptedWarningsDispatch={acceptedWarningsDispatch}
                      />
                    )}
                    {currentStep.type === 'upload' && (
                      <UploadFiles
                        batchId={batchId}
                        batchName={batchName}
                        filesToUpload={orderMediaFiles}
                        openStartUploadModal={(acceptedFiles) =>
                          openStartFileUploadModal(acceptedFiles)
                        }
                        openLinkedAccountModal={openLinkedAccountModal}
                        openLinksModal={() => setShowLinksModal(true)}
                        openSampleFileModal={() => setShowSampleFileModal(true)}
                        rejectedFiles={rejectedFiles}
                        removeFromUploadList={(index) => removeFromUploadList(index)}
                        saveTemplate={saveTemplate}
                        selectedServices={selectedServices}
                        setAlignmentTexts={setAlignmentTexts}
                        setBatchError={setBatchError}
                        setBatchId={setBatchId}
                        setBatchName={setBatchName}
                        setFilesToUpload={(files) => setOrderMediaFiles(files)}
                        setRejectedFiles={(rejectedFiles) => updateRejectedFiles(rejectedFiles)}
                        setTemplateName={setTemplateName}
                        showProofToFinal={accountTypeHash.studio}
                        showTemplateForm={features.orderTemplates}
                        templateErrors={templateErrors}
                        templateName={templateName}
                        templateSaved={templateSaved}
                        uploading={uploading}
                        uploadProgress={uploadProgress}
                        validFileUploads={validFileUploads}
                        wordlistAssignment={wordlistAssignment}
                      />
                    )}
                    {currentStep.type === 'finalize' && (
                      <FinalizeOrder
                        defaultTranslationVendorId={Number(defaultTranslationVendorId)}
                        durationMinimums={durationMinimums}
                        mediaFiles={orderMediaFiles}
                        selectedServices={selectedServices}
                        selectedTranslationType={selectedTranslationType}
                        showTransperfectDurationMinimum={showTransperfectDurationMinimum}
                        sourceLanguage={sourceLanguage}
                        displayBetaTerms={displayBetaTerms()}
                        betaTermsAccepted={betaTermsAccepted}
                        setBetaTermsAccepted={setBetaTermsAccepted}
                        betaTermsDisplayDate={betaTermsDisplayDate()}
                      />
                    )}
                  </StepContainer>
                </Col>
                <Col sm={4} className={css(styles.sidePanel)}>
                  {currentStep.type !== 'finalize' && (
                    <SelectedServicesPanel
                      hasProofToFinalFile={orderMediaFiles.some((f) => f['proofToFinal'])}
                      selectedServices={selectedServices}
                      selectedTranslationType={selectedTranslationType}
                    />
                  )}
                  {currentStep < 3 &&
                    selectedServices.length > 0 &&
                    selectedServices.some((service) => service.serviceType === 'Alignment') && (
                      <AlignmentTipPanel />
                    )}
                  {currentStep.type === 'secondaryServices' &&
                    selectedServices.length > 0 &&
                    selectedServices.some((service) => service.serviceType === 'Translation') &&
                    translationTypes.length > 0 && (
                      <TranslationTypeInfoPanel translationTypes={translationTypes} />
                    )}
                  {currentStep.type === 'secondaryServices' &&
                    selectedServices.length > 0 &&
                    selectedServices.some((service) => service.serviceType === 'Translation') && (
                      <TranslationVendorInfoPanel
                        selectedVendor={translationVendorForInfo}
                        threeplaySourcedEnabled={translationTypes.length > 0}
                        transperfectMinimumDuration={Number(durationMinimums.transperfect)}
                      />
                    )}
                  {currentStep.type === 'secondaryServices' &&
                    selectedServices.length > 0 &&
                    selectedServices.some(
                      (service) => service.serviceType === 'BroadcastScript'
                    ) && <BroadcastScriptsInfoPanel />}
                  {currentStep.type === 'finalize' && <FaqPanel />}
                </Col>
              </Row>
            </Container>
            <AlignmentErrorModal
              filesToUpload={orderMediaFiles}
              closeAlignmentErrorModal={() => setShowAlignmentErrorModal(false)}
              show={showAlignmentErrorModal}
            />
            <LinksModal
              closeLinksModal={() => setShowLinksModal(false)}
              setFilesToUpload={(files) => setOrderMediaFiles(files)}
              show={showLinksModal}
              words={
                wordlistAssignment &&
                wordlistAssignment.wordlist &&
                wordlistAssignment.wordlist.words
              }
            />
            <LinkedAccountModal
              closeLinkedAccountModal={() => setShowLinkedAccountModal(false)}
              filesToUpload={orderMediaFiles}
              integrations={integrations}
              platformDetails={integrationPlatformDetails}
              setLinkedFilesToUpload={(type, videoData) => setLinkedFilesToUpload(type, videoData)}
              show={showLinkedAccountModal}
            />
            <SampleFileModal
              closeSampleFileModal={() => setShowSampleFileModal(false)}
              setFilesToUpload={(files) => setOrderMediaFiles(files)}
              show={showSampleFileModal}
              words={
                wordlistAssignment &&
                wordlistAssignment.wordlist &&
                wordlistAssignment.wordlist.words
              }
            />
            <StartFileUploadModal
              acceptedFiles={acceptedFiles}
              closeStartUploadModal={() => setShowStartFileUploadModal(false)}
              show={showStartFileUploadModal}
              startUpload={startUpload}
            />
            <StartCheatsheetUploadModal
              selectedCheatsheet={cheatsheetToUpload}
              closeStartUploadCheatsheetModal={() => setShowStartCheatsheetUploadModal(false)}
              show={showStartCheatsheetUploadModal}
              startCheatsheetUpload={startCheatsheetUpload}
            />
          </ErrorBoundary>
        </QueryClientProvider>
      </ProjectContext.Provider>
    );
  }
}

OrderForm.propTypes = {
  accountTypeHash: PropTypes.objectOf(PropTypes.string),
  aiDubbingOrderOptions: PropTypes.arrayOf(aiDubbingOrderOptionType),
  descriptiveTranscriptPrices: PropTypes.object,
  dubbingOrderOptions: PropTypes.arrayOf(PropTypes.any),
  features: PropTypes.objectOf(PropTypes.bool),
  fileFormats: PropTypes.shape({
    supportedFormat: PropTypes.string,
    videoFormat: PropTypes.string,
  }),
  formAuthenticityToken: PropTypes.string,
  hasActivePricingBlocks: PropTypes.bool,
  liveUpgradeDetails: liveUpgradeDetailsType,
  mixingOrderOptions: mixingOrderOptionsType,
  orderTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  orderableServices: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      serviceType: PropTypes.string,
      price: PropTypes.shape({
        amount: PropTypes.integer,
        unit: PropTypes.string,
      }),
      description: PropTypes.string,
      additionalServices: PropTypes.shape({
        displayName: PropTypes.string,
        serviceType: PropTypes.string,
        price: PropTypes.shape({
          amount: PropTypes.integer,
          unit: PropTypes.string,
        }),
        description: PropTypes.string,
      }),
    })
  ),
  postOrderRedirect: PropTypes.bool,
  postOrderRedirectUrl: PropTypes.string,
  resourceConfigurations: PropTypes.objectOf(PropTypes.integer),
  termsAcceptances: PropTypes.object,
};

const styles = StyleSheet.create({
  sidePanel: {
    height: '100%',
    position: 'sticky',
    right: '0',
    top: '10px',
  },
});

export default OrderForm;
