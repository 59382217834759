import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { updateAccountPlanMutation } from './data/mutations';
import { threeplayApi } from '~/logic/ThreeplayApi';
import AlertMessage from '~/components/app/common/AlertMessage';
import { formatDateMonthDay } from './utils/AccountPlanUtils';
import { numberToCurrency } from '~/helpers/numbers';

function AutoRenewModal(props) {
  const [error, setError] = useState('');
  const [autoRenew, setAutoRenew] = useState(false);
  const [renewalPaymentFrequency, setRenewalPaymentFrequency] = useState();
  const [renewCheckboxText, setRenewCheckboxText] = useState('');

  useEffect(() => {
    setAutoRenew(props.autoRenew);
  }, [props.autoRenew]);

  useEffect(() => {
    setRenewalPaymentFrequency(props.renewalPaymentFrequency);
  }, [props.renewalPaymentFrequency]);

  useEffect(() => {
    const text = `Yes, automatically renew my account plan on ${formatDateMonthDay(
      props.termStart
    )} for ${paymentByFrequency(props.defaultPrice, renewalPaymentFrequency)}.`;
    setRenewCheckboxText(text);
  }, [props.defaultPrice, props.termStart, renewalPaymentFrequency]);

  function handleAccountPlanSave() {
    setError('');
    threeplayApi
      .request(updateAccountPlanMutation, {
        plan: {
          autoRenew: autoRenew,
          renewalPaymentFrequency: renewalPaymentFrequency,
          planId: props.accountPlanId,
        },
      })
      .then((response) => {
        if (response.errors) {
          setError(response.errors.join(', '));
        } else {
          props.onSave();
          props.closeModal();
        }
      });
  }

  function paymentByFrequency(defaultPrice, paymentFrequency) {
    if (paymentFrequency === 'annual') {
      return `${numberToCurrency.format(defaultPrice)} annually`;
    } else {
      return `${numberToCurrency.format(defaultPrice / 12)} ${paymentFrequency}`;
    }
  }

  return (
    <Modal dialogClassName="modal-width-medium" show={props.show} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Account Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <AlertMessage message={error} variant="danger" />}
        <Form>
          <Form.Group controlId="modalRenewFrequency">
            <Form.Label>
              <strong>Billing Frequency for Renewal</strong>
            </Form.Label>
            <Form.Check
              aria-label={'Monthly'}
              aria-checked={renewalPaymentFrequency === 'monthly'}
              checked={renewalPaymentFrequency === 'monthly'}
              label={'Monthly'}
              onChange={() => setRenewalPaymentFrequency('monthly')}
              name="renewFrequency"
              type="radio"
            />
            <Form.Check
              aria-label={'Annually'}
              aria-checked={renewalPaymentFrequency === 'annual'}
              checked={renewalPaymentFrequency === 'annual'}
              label={'Annually'}
              onChange={() => setRenewalPaymentFrequency('annual')}
              name="renewFrequency"
              type="radio"
            />
          </Form.Group>
          <Form.Label>
            <strong>Auto Renew</strong>
          </Form.Label>
          <Form.Group controlId="modalRenew">
            <Form.Check
              aria-checked={autoRenew}
              checked={autoRenew}
              label={renewCheckboxText}
              onChange={() => setAutoRenew(!autoRenew)}
              type="checkbox"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-flex-end">
        <>
          <Button className="mr-2" variant="outline-dark" onClick={props.closeModal}>
            Cancel
          </Button>
          <Button
            disabled={
              autoRenew === props.autoRenew &&
              renewalPaymentFrequency === props.renewalPaymentFrequency
            }
            onClick={handleAccountPlanSave}
            variant="primary"
          >
            Save
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
}

AutoRenewModal.propTypes = {
  accountPlanId: PropTypes.string,
  autoRenew: PropTypes.bool,
  closeModal: PropTypes.func,
  defaultPrice: PropTypes.number,
  onSave: PropTypes.func,
  paymentFrequency: PropTypes.string,
  renewalPaymentFrequency: PropTypes.string,
  show: PropTypes.bool,
  termStart: PropTypes.string,
};

export default AutoRenewModal;
