import React from 'react';

import Card from 'react-bootstrap/Card';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { CHOOSE_PLAN_START_TAB_INDEX } from '~/helpers/constants';
import { formatDateMonthDay } from '../utils/AccountPlanUtils';
import { checkKeyPress } from '~/helpers/checkKeyPress';
import selectedStyles from '../../styles/selectedDeselectedStyles';

function ChoosePlan(props) {
  function selectPlan(planName) {
    props.setSelectedPlan(planName);
    if (planName === 'express') {
      props.setSelectedFrequency(null);
    }
  }

  return (
    <>
      <Container fluid={true}>
        <Row className="h6 font-weight-bold mt-3">Select Plan</Row>
        <Row className={css(styles.flex)}>
          <Card
            tabIndex={CHOOSE_PLAN_START_TAB_INDEX}
            className={
              'm-1 ' +
              css(styles.planCard) +
              ' ' +
              css(
                props.selectedPlan == 'pro'
                  ? selectedStyles.selectedSimple
                  : selectedStyles.deselectedSimple
              )
            }
            onClick={() => props.setSelectedPlan('pro')}
            onKeyDown={(e) => checkKeyPress(e, () => selectPlan('pro'), { triggerOnSpace: true })}
            role="radio"
          >
            <Card.Body>
              <Container>
                <Row className="h5 mt-1 font-weight-bold text-secondary">Pro Plan</Row>
                <Row className="h3 threeplay-blue font-weight-bold">
                  {props.calculatePrice({ monthly: false, renewal: false })}
                </Row>
                <Row className="text-secondary">
                  first year annual commitment (billed monthly or annually)
                </Row>
                <Row>
                  Access to Pro features and services including multiple projects, 30+ integrations,
                  translation, live captioning, and technical support
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Row>
        {props.selectedPlan == 'pro' && (
          <>
            <Row className="h6 font-weight-bold mt-3">Select Billing Frequency</Row>
            <Row className={css(styles.flex)}>
              <Card
                tabIndex={CHOOSE_PLAN_START_TAB_INDEX + 1}
                className={
                  'm-1 ' +
                  css(styles.planCard) +
                  ' ' +
                  css(
                    props.selectedFrequency == 'monthly'
                      ? selectedStyles.selectedSimple
                      : selectedStyles.deselectedSimple
                  )
                }
                onClick={() => props.setSelectedFrequency('monthly')}
                onKeyDown={(e) =>
                  checkKeyPress(e, () => props.setSelectedFrequency('monthly'), {
                    triggerOnSpace: true,
                  })
                }
                role="radio"
              >
                <Card.Body>
                  <Container>
                    <Row className="h5 mt-1 font-weight-bold text-secondary">Monthly Billing</Row>
                    <Row className="h3 threeplay-blue font-weight-bold">
                      {props.calculatePrice({ monthly: true, renewal: false })}
                    </Row>
                    <Row className="text-secondary">per month (for the next 12 months)</Row>
                  </Container>
                </Card.Body>
              </Card>
              <Card
                tabIndex={CHOOSE_PLAN_START_TAB_INDEX + 2}
                className={
                  'm-1 ' +
                  css(styles.planCard) +
                  ' ' +
                  css(
                    props.selectedFrequency == 'annual'
                      ? selectedStyles.selectedSimple
                      : selectedStyles.deselectedSimple
                  )
                }
                onClick={() => props.setSelectedFrequency('annual')}
                onKeyDown={(e) =>
                  checkKeyPress(e, () => props.setSelectedFrequency('annual'), {
                    triggerOnSpace: true,
                  })
                }
                role="radio"
              >
                <Card.Body>
                  <Container>
                    <Row className="h5 mt-1 font-weight-bold text-secondary">Annual Billing</Row>
                    <Row className="h3 threeplay-blue font-weight-bold">
                      {props.calculatePrice({ monthly: false, renewal: false })}
                    </Row>
                    <Row className="text-secondary">this year (billed today)</Row>
                  </Container>
                </Card.Body>
              </Card>
            </Row>
            <Row className="h6 font-weight-bold mt-3">
              Do you want your plan to automatically renew at the end of each billing period?
            </Row>
            <Row className="mt-3">
              <Form>
                <Form.Group controlId="autoRenew" className={css(styles.flex)}>
                  <Form.Check
                    aria-checked={props.autoRenew}
                    checked={props.autoRenew}
                    label={`Yes, automatically renew and charge me each year on ${formatDateMonthDay(
                      Date.now()
                    )}.
                       I understand and agree that my first renewal will be ${props.calculatePrice({
                         monthly: false,
                         renewal: true,
                       })}`}
                    onChange={() => props.setAutoRenew(!props.autoRenew)}
                    type="checkbox"
                    tabIndex={CHOOSE_PLAN_START_TAB_INDEX + 3}
                  />
                </Form.Group>
              </Form>
            </Row>
          </>
        )}
      </Container>
    </>
  );
}

ChoosePlan.propTypes = {
  autoRenew: PropTypes.bool,
  calculatePrice: PropTypes.func,
  selectedFrequency: PropTypes.string,
  selectedPlan: PropTypes.string,
  setAutoRenew: PropTypes.func,
  setSelectedFrequency: PropTypes.func,
  setSelectedPlan: PropTypes.func,
};

const styles = StyleSheet.create({
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-10px',
  },
  price: {
    color: '#007EB5',
  },
  planCard: {
    width: 'calc(50% - 15px)',
  },
  stepTitle: {
    'font-weight': 'bold',
    'margin-bottom': '.25rem',
  },
});

export default ChoosePlan;
