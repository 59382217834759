import React from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import CreditCardLabel from './CreditCardLabel';
import { MANAGE_PLAN_PAYMENT_START_TAB_INDEX } from '~/helpers/constants';

function PaymentMonthly(props) {
  return (
    <>
      <Row>
        3Play requires a credit card on file for a monthly Pro subscription plan. All future
        purchases or charges associated with your account will be charged to this credit card.
      </Row>
      <Row className="h5 mt-3 font-weight-bold">Select Payment Method</Row>
      {!props.hasValidCard() && (
        <div className="ml-3">A valid credit card is required to upgrade to the Pro plan.</div>
      )}
      {props.paymentAccounts.length > 0 && (
        <Container>
          <Row>Select a credit card account for payment</Row>
          <Form>
            <Form.Group>
              {props.paymentAccounts.map((account, idx) => (
                <Form.Check type="radio" key={account.id.toString()} value={account.id}>
                  <Form.Check.Input
                    type="radio"
                    checked={
                      props.paymentType === 'payment_account' &&
                      props.paymentAccount === parseInt(account.id)
                    }
                    role="radio"
                    tabIndex={MANAGE_PLAN_PAYMENT_START_TAB_INDEX + idx}
                    onChange={() => props.onPaymentChange('payment_account', account.id)}
                  />
                  <Form.Check.Label>
                    <CreditCardLabel
                      card={{
                        billingContactName: account.billingContactName,
                        lastDigits: `${account.lastDigits}`.padStart(4, '0'),
                        name: account.name,
                      }}
                    />
                  </Form.Check.Label>
                </Form.Check>
              ))}
            </Form.Group>
          </Form>
        </Container>
      )}
    </>
  );
}

PaymentMonthly.propTypes = {
  hasValidCard: PropTypes.func,
  onPaymentChange: PropTypes.func,
  paymentAccount: PropTypes.number,
  paymentAccounts: PropTypes.arrayOf(PropTypes.object),
  paymentType: PropTypes.string,
};

export default PaymentMonthly;
